#a-img {
  margin: 100px 0;
  width: calc(var(--content-width) * 0.8);
  height: calc(var(--content-width) * 0.8 * 0.62);
  background: var(--light-grey);
  position: relative;
}

#a-img > div,
#a-img > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#a-img > div > img,
#a-img > a > img {
  object-fit: cover;
  height: 100%;
  transition: 0.3s;
}

#a-img-label {
  font-size: 0.9rem;
  bottom: -1rem;
  position: absolute;
  left: 0;
  font-style: italic;
}

.a-img-change-button {
  position: absolute;
  height: 100%;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 0;
  left: -10%;
  background: transparent;
  border: none;
}

.a-img-change-button:last-child {
  left: auto;
  right: -10%;
}

.a-img-change-button:hover {
  background: #00000044;
}

.a-img-change-button::after {
  content: "";
  position: absolute;
  border-left: 2px solid var(--secondary-color);
  border-top: 2px solid var(--secondary-color);
  transform: rotate(-45deg);
  pointer-events: none;
  width: 2vw;
  height: 2vw;
  margin-left: 1vw;
}

.a-img-change-button:last-child::after {
  transform: rotate(135deg);
  margin-left: -1vw;
}

#a-date {
  font-size: 15px;
  margin-bottom: 40px;
  color: var(--accent-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 1120px) {
  #a-img {
    margin: 32px 0;
    width: calc((100vw - 64px));
    height: calc((100vw - 64px) * 0.62);
    max-width: 800px;
    max-height: calc(800px * 0.62);
  }

  #a-date {
    margin-bottom: 32px;
  }
}

#a-title-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.a-title-row-button {
  font-size: 1rem;
  background: transparent;
  padding: 6px 12px;
  /* border: 1px solid var(--secondary-color); */
  cursor: pointer;
  color: var(--secondary-color);
  text-decoration: none;
}

.a-title-row-button:hover {
  /* background: var(--secondary-color); */
  /* color: white; */
  text-decoration: underline;
}

#a-date > svg {
  fill: var(--accent-color);
  margin-right: 5px;
  margin-top: -2px;
  width: 16px;
  height: 16px;
}

#a-divider {
  margin: 72px 0;
  height: 1px;
  width: 20%;
  background: var(--light-grey);
}

@media only screen and (max-width: 1120px) {
  #a-divider {
    margin: 32px 0;
    height: 1px;
    width: 50%;
  }
}

#a-content {
  width: 800px;
  text-align: start;
}

#a-content * {
  font-family: Lato !important;
  font-size: 1rem !important;
}

#a-content img {
  object-fit: cover;
  width: 100%;
}

#a-content iframe {
  border: none;
  width: 100%;
}

#a-buttons {
  margin: 100px 0 120px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(var(--content-width) * 0.8);
}

#a-buttons > a:nth-of-type(1):hover {
  background: var(--accent-color) !important;
}

@media only screen and (max-width: 1120px) {
  #a-content {
    width: 100%;
    max-width: 800px;
  }

  #a-buttons {
    max-width: 800px;
    margin: 32px 0 48px 0;
    width: 100%;
  }
}
