.big-news-tile {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  margin-top: 40px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  text-decoration: none;
  color: var(--text-color);
  height: calc(var(--content-width) * 0.4 * 0.62 - 1px);
  overflow: hidden;
}

.big-news-tile:nth-child(odd) {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.big-news-tile:hover,
.big-news-tile:focus {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.big-news-tile-content {
  padding: 40px;
  width: 60%;
}

.big-news-tile-title {
  font-size: 21px;
  font-weight: 600;
  font-family: Montserrat;
  margin-bottom: 20px;
}

.big-news-tile-date {
  font-size: 15px;
  margin-bottom: 30px;
  color: var(--accent-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.big-news-tile-date > svg {
  fill: var(--accent-color);
  margin-right: 5px;
  margin-top: -2px;
  width: 16px;
  height: 16px;
}

.big-news-tile-desc {
  overflow: hidden;
  height: calc(var(--content-width) * 0.4 * 0.62 - 80px - 45px - 48px);
  max-height: calc(var(--content-width) * 0.4 * 0.62 - 80px - 45px - 48px);
  position: relative;
}

.big-news-tile-desc::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, transparent, #ffffff);
}

.big-news-tile-desc * {
  font-family: Lato !important;
  font-size: 1rem !important;
}

.big-news-tile-image {
  width: 40%;
  height: calc(var(--content-width) * 0.4 * 0.62);
  background: var(--light-grey);
  position: relative;
}

.big-news-tile-image > div {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.big-news-tile-image > div > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.big-news-tile:hover img {
  transform: scale(1.1);
}

#news-more {
  margin: 70px 0 0 calc(50% - 85px);
  border: none;
  background: var(--secondary-color);
  color: #ffffff;
  font-family: Montserrat;
  font-size: 1rem;
  padding: 18px 34px;
  cursor: pointer;
}

#news-more:hover,
#news-more:focus {
  color: var(--accent-color);
}

@media only screen and (min-width: 550px) and (max-width: 1120px) {
  .big-news-tile {
    height: auto;
  }
  
  .big-news-tile:nth-child(even) {
    margin-top: 16px;
    flex-direction: column-reverse;
    float: right;
    width: calc(50% - 8px);
  }

  .big-news-tile:nth-child(odd) {
    margin-top: 16px;
    flex-direction: column-reverse;
    float: left;
    width: calc(50% - 8px);
  }

  .big-news-tile-content {
    padding: 16px 16px 24px;
    width: 100%;
  }

  .big-news-tile-title {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .big-news-tile-date {
    font-size: 14px;
    margin-bottom: 18px;
  }

  .big-news-tile-image {
    width: 100%;
    height: calc((50vw - 24px) * 0.62);
  }

  #news-more {
    margin: 40px 0 0 calc(50% - 85px);
  }
}

@media only screen and (max-width: 550px) {
  .big-news-tile {
    height: auto;
  }

  .big-news-tile:nth-child(2) {
    margin-top: 32px !important;
  }

  .big-news-tile {
    margin-top: 16px;
    flex-direction: column-reverse !important;
  }

  .big-news-tile-content {
    padding: 16px 16px 24px;
    width: 100%;
  }

  .big-news-tile-title {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .big-news-tile-date {
    font-size: 14px;
    margin-bottom: 18px;
  }

  .big-news-tile-image {
    width: 100%;
    height: calc((100vw - 32px) * 0.62);
  }

  #news-more {
    margin: 40px 0 0 calc(50% - 85px);
  }
}

#news-filters-sort-button {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1rem;
  padding: 6px 12px;
  border-radius: 3px;
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.2s;
  outline: transparent;
}

#news-filters-sort-button:hover,
#news-filters-sort-button:focus {
  background: var(--light-grey);
}

#news-filters-sort-button > div {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

#news-filters-sort-button:hover > div::before,
#news-filters-sort-button:focus > div::before {
  transform: rotate(-180deg);
}

#news-filters-sort-button:hover > div::after,
#news-filters-sort-button:focus > div::after {
  transform: rotate(-135deg);
}

#news-filters-sort-button > div::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 14px;
  background: black;
  transition: 0.2s;
}

#news-filters-sort-button > div::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(45deg);
  transition: 0.2s;
}
