#o-image-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

#o-image-row > div {
  height: 160px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
  cursor: pointer;
}

#o-image-row > div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #00000088;
  pointer-events: none;
}

#o-image-row > div:hover::after {
  opacity: 1;
}

#o-image-row img {
  max-height: 160px;
  height: 160px;
}

#o-image-row svg {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  width: 32px;
  height: 32px;
  z-index: 1;
}

#o-image-row > div:hover > svg {
  opacity: 1;
}

#o-miejscowosc {
  font-size: 20px;
  margin: 30px 0 40px 0;
}

#o-rodzaj, #o-data, #o-zabytek {
  line-height: 1.7;
  font-size: 16px;
}

#o-icon-row {
  margin: 60px 0;
  width: 100%;
  height: 1px;
  background: var(--accent-color);
}

#o-mapa {
  width: 100vw;
  height: calc(400px + 20vw);
  background: var(--light-grey);
  position: relative;
}