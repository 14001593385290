#historia-content {
  width: 1000px;
  display: flex;
  flex-direction: row;
  margin-bottom: 120px;
  align-items: center;
}

#historia-images {
  display: flex;
  flex-direction: column;
}

.historia-image {
  width: calc(1000px * 0.4);
  /* height: calc(1000px * 0.4 * 0.62); */
  margin-bottom: 120px;
  overflow: hidden;
}

.historia-image > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#historia-desc {
  margin-left: 60px;
  line-height: 1.5;
}

@media only screen and (max-width: 1120px) {
  #historia-content {
    width: calc(100vw - 32px);
    flex-direction: column;
    margin-bottom: 48px;
    padding: 0 16px;
    position: relative;
  }

  #historia-images {
    flex-direction: row;
  }

  .historia-image {
    width: calc((100vw - 32px) / 3 - 16px);
    height: calc(((100vw - 32px) / 3 - 16px) * 0.62);
    margin: 0 8px;
    margin-bottom: 24px;
  }

  .historia-image:last-child {
    display: none;
  }

  #historia-desc {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 1120px) and (min-width: 770px) {
  .historia-image {
    width: calc((100vw - 32px) / 3 - 16px);
    height: calc(((100vw - 32px) / 3 - 16px) * 0.62);
    margin: 0 8px;
    margin-bottom: 24px;
  }

  .historia-image:last-child {
    display: none;
  }
}

@media only screen and (max-width: 770px) and (min-width: 520px) {
  #historia-content {
    padding-bottom: calc(((100vw - 32px) / 2 - 16px) * 0.62 + 24px);
  }

  .historia-image {
    width: calc((100vw - 32px) / 2 - 16px);
    height: calc(((100vw - 32px) / 2 - 16px) * 0.62);
    margin: 0 8px;
    margin-bottom: 24px;
  }

  .historia-image:nth-child(3),
  .historia-image:last-child {
    display: block;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }

  .historia-image:last-child {
    right: 16px;
  }
}

@media only screen and (max-width: 520px) {
  #historia-content {
    padding-bottom: calc(((100vw - 32px) - 16px) * 0.62 + 24px);
  }

  .historia-image {
    width: calc((100vw - 32px) - 16px);
    height: calc(((100vw - 32px) - 16px) * 0.62);
    margin: 0 8px;
    margin-bottom: 24px;
  }

  .historia-image:nth-child(2) {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }

  .historia-image:nth-child(3),
  .historia-image:last-child {
    display: none;
  }
}
