#title-bar {
  width: 100%;
  height: 50px;
  background: #312c32;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 calc((100% - (32.2% + 690px)) / 2);
}

#title-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#title-icon-row > a > svg {
  min-width: 20px;
  margin-left: 16px;
  padding: 0 1px;
  cursor: pointer;
  fill: rgba(255, 255, 255, 0.9);
}

#title-icon-row > a:nth-child(1):hover > svg,
#title-icon-row > a:nth-child(1):focus > svg {
  fill: var(--facebook-color);
}

#title-icon-row > a:nth-child(2):hover > svg,
#title-icon-row > a:nth-child(2):focus > svg {
  fill: var(--instagram-color);
}

#title-icon-row > a:nth-child(3):hover > svg,
#title-icon-row > a:nth-child(3):focus > svg {
  fill: var(--youtube-color);
}

#title-icon-row > a:nth-child(4):hover > svg,
#title-icon-row > a:nth-child(4):focus > svg {
  fill: #ec6d00;
}

.tb-icon-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

#menu {
  width: 100%;
  height: 50px;
  background: var(--primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: relative;
}

.menu-tile {
  position: relative;
  height: 100%;
  padding: 0 2.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
  font-size: 1rem;
  background: transparent;
  border: none;
  outline: transparent;
}

.menu-tile:hover,
.menu-tile:focus,
.menu-tile:focus-within {
  background: #312c32;
}

.menu-tile:hover,
.menu-tile:focus {
  background: #312c32;
}

.menu-tile-elements {
  position: absolute;
  top: 50px;
  left: 0px;
  background: #312c32;
  padding: 24px 0;
  transition: 0.2s;
  opacity: 0;
  pointer-events: none;
  transform: translate(0, -100%);
  transform-origin: top;
  z-index: -1;
}

.menu-tile:hover .menu-tile-elements,
.menu-tile:focus .menu-tile-elements,
.menu-tile-elements:focus-within {
  opacity: 1;
  pointer-events: all;
  transform: translate(0);
}

.menu-tile:hover .menu-tile-elements,
.menu-tile:focus .menu-tile-elements {
  opacity: 1;
  pointer-events: all;
  transform: translate(0);
}

.menu-tile-elements-title {
  white-space: nowrap;
  padding: 6px 24px;
  display: block;
  color: #ffffff;
  text-decoration: none;
}

.menu-tile-elements-title:hover,
.menu-tile-elements-title:focus {
  color: #daad86;
}

#tb-kontakt-body {
  position: absolute;
  top: 124px;
  right: calc((100vw - (32.2% + 690px)) / 2);
  width: 300px;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  padding: 24px;
  transition: 0.1s;
}

#tb-kontakt-body svg {
  fill: var(--light-grey);
}

#tb-kontakt-body-title {
  font-size: 1.2rem;
  width: 100%;
  font-family: Montserrat;
}

#tb-kontakt-body-close-button {
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  background: transparent;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: transparent;
}

#tb-kontakt-body-close-button::before,
#tb-kontakt-body-close-button::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  background: var(--secondary-color);
}

#tb-kontakt-body-close-button::before {
  transform: rotate(45deg);
}

#tb-kontakt-body-close-button::after {
  transform: rotate(-45deg);
}

#tb-kontakt-body-close-button:hover::before,
#tb-kontakt-body-close-button:hover::after,
#tb-kontakt-body-close-button:focus::before,
#tb-kontakt-body-close-button:focus::after {
  background: var(--primary-color);
}

/* MOBILE */

#mobile-top-bar {
  width: 100vw;
  height: 50px;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  z-index: 10;
}

#mtb-menu-button {
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  height: 18px;
  width: 24px;
}

#mtb-menu-button > div {
  width: 24px;
  height: 2px;
  background: #ffffff;
  transition: 0.3s;
  position: absolute;
  left: 0;
}

#mtb-menu-button > div:nth-child(1) {
  top: 0;
}

#mtb-menu-button > div:nth-child(3) {
  bottom: 0;
}

#mtb-menu-list {
  display: flex;
  flex-direction: column;
  background: var(--primary-color);
  width: 100vw;
  position: fixed;
  top: 50px;
  left: 0;
  transition: 0.3s;
  z-index: 9;
  max-height: calc(100vh - 50px);
  overflow: auto;
}

#mtb-menu-list > .menu-tile {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 1px solid #ffffff44;
  background: transparent !important;
}

#mtb-menu-list > .menu-tile > .menu-tile-title {
  padding: 16px;
}

.mtb-tile-elements {
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  transition: 0.2s;
}

#mtb-menu-list > .menu-tile:hover > .mtb-tile-elements {
  height: 260px;
}

#mtb-menu-list > .menu-tile:nth-child(5):hover > .mtb-tile-elements {
  height: 208px;
}

.mtb-tile-elements-title {
  padding: 16px;
  text-decoration: none;
  color: #ffffff;
}

#mtb-background {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000000aa;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;
}
