.news-tile {
  background: white;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  text-decoration: none;
  color: var(--text-color);
}

.news-tile:hover,
.news-tile:focus {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.news-tile-image {
  width: 100%;
  padding-top: 62%;
  background: var(--light-grey);
  position: relative;
}

.news-tile-image > div {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.news-tile-image > div > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.news-tile:hover img {
  transform: scale(1.1);
}

.news-tile-date {
  color: var(--accent-color);
  font-size: 0.9rem;
  margin: 16px 0 0 12px;
}

.news-tile-title {
  font-weight: bold;
  margin: 16px 0 16px 12px;
  font-size: 1.1rem;
}

.news-tile-opis {
  margin: 12px 0 16px 12px;
  font-size: 0.95rem;
}

.news-tile-read-more {
  color: var(--accent-color);
  font-size: 0.9rem;
  margin: 16px 0 24px 12px;
}
