@import url(https://fonts.googleapis.com/css?family=Lato|Montserrat:500&display=swap);
body {
  --primary-color: #2e9dae;
  --secondary-color: #312c32;
  --accent-color: #daad86;
  --light-grey: #666666;
  --text-color: #03080b;
  --facebook-color: #4267b2;
  --instagram-color: #fbad50;
  --youtube-color: rgb(248, 0, 0);
  --content-width: calc(32.2vw + 690px);

  margin: 0;
  font-family: "Lato";
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 1120px) {
  body {
    --content-width: calc(100vw - 32px);
  }
}

#a-content a {
  color: var(--text-color);
}

#a-content a:hover {
  color: var(--primary-color);
}

button {
  font-family: Lato;
  color: var(--text-color);
}

#content {
  background: #f6f6f6;
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (min-width: 1120px) {
  #content {
    padding: 0 calc((100% - (32.2% + 690px)) / 2);
  }
}

@media only screen and (max-width: 1120px) {
  #content {
    padding: 0 16px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  font-family: Montserrat;
}

.title-top {
  padding-top: 100px;
}

#a-title {
  text-align: center;
  width: 800px;
  font-size: 27px;
  font-family: Montserrat;
  font-weight: 700;
}

@media only screen and (max-width: 1120px) {
  .title {
    font-size: 20px;
  }

  .title-top {
    padding-top: 32px;
  }

  #a-title {
    width: 100%;
    font-size: 20px;
  }
}

.square-button {
  background: transparent;
  border: 1px solid var(--secondary-color);
  fill: var(--secondary-color);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s;
}

.square-button:hover {
  color: #ffffff;
  background: var(--secondary-color);
}

.square-button > svg {
  fill: inherit;
}

.square-button:hover > svg {
  fill: #ffffff;
}

.square-button-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--secondary-color);
  margin: 0 2px;
}

#loading {
  width: 100%;
  text-align: center;
  padding: 36px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  --animation-duration: 0.6s;
}

#loading > div {
  width: 15px;
  height: 15px;
  margin: 0 4px;
  border-radius: 50%;
  background: var(--primary-color);
}

#loading > div:nth-child(1) {
  animation: var(--animation-duration) loadingAnim0 infinite ease-out;
}

@keyframes loadingAnim0 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1) translateX(0);
  }
  100% {
    transform: translateX(23px);
  }
}

#loading > div:nth-child(2) {
  animation: var(--animation-duration) loadingAnim1 infinite ease-out;
}

@keyframes loadingAnim1 {
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(23px);
  }
}

#loading > div:nth-child(3) {
  animation: var(--animation-duration) loadingAnim2 infinite ease-out;
}

@keyframes loadingAnim2 {
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

#title-bar {
  width: 100%;
  height: 50px;
  background: #312c32;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 calc((100% - (32.2% + 690px)) / 2);
}

#title-icon-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#title-icon-row > a > svg {
  min-width: 20px;
  margin-left: 16px;
  padding: 0 1px;
  cursor: pointer;
  fill: rgba(255, 255, 255, 0.9);
}

#title-icon-row > a:nth-child(1):hover > svg,
#title-icon-row > a:nth-child(1):focus > svg {
  fill: var(--facebook-color);
}

#title-icon-row > a:nth-child(2):hover > svg,
#title-icon-row > a:nth-child(2):focus > svg {
  fill: var(--instagram-color);
}

#title-icon-row > a:nth-child(3):hover > svg,
#title-icon-row > a:nth-child(3):focus > svg {
  fill: var(--youtube-color);
}

#title-icon-row > a:nth-child(4):hover > svg,
#title-icon-row > a:nth-child(4):focus > svg {
  fill: #ec6d00;
}

.tb-icon-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

#menu {
  width: 100%;
  height: 50px;
  background: var(--primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: relative;
}

.menu-tile {
  position: relative;
  height: 100%;
  padding: 0 2.3%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  transition: 0.2s;
  font-size: 1rem;
  background: transparent;
  border: none;
  outline: transparent;
}

.menu-tile:hover,
.menu-tile:focus,
.menu-tile:focus-within {
  background: #312c32;
}

.menu-tile:hover,
.menu-tile:focus {
  background: #312c32;
}

.menu-tile-elements {
  position: absolute;
  top: 50px;
  left: 0px;
  background: #312c32;
  padding: 24px 0;
  transition: 0.2s;
  opacity: 0;
  pointer-events: none;
  transform: translate(0, -100%);
  transform-origin: top;
  z-index: -1;
}

.menu-tile:hover .menu-tile-elements,
.menu-tile:focus .menu-tile-elements,
.menu-tile-elements:focus-within {
  opacity: 1;
  pointer-events: all;
  transform: translate(0);
}

.menu-tile:hover .menu-tile-elements,
.menu-tile:focus .menu-tile-elements {
  opacity: 1;
  pointer-events: all;
  transform: translate(0);
}

.menu-tile-elements-title {
  white-space: nowrap;
  padding: 6px 24px;
  display: block;
  color: #ffffff;
  text-decoration: none;
}

.menu-tile-elements-title:hover,
.menu-tile-elements-title:focus {
  color: #daad86;
}

#tb-kontakt-body {
  position: absolute;
  top: 124px;
  right: calc((100vw - (32.2% + 690px)) / 2);
  width: 300px;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  padding: 24px;
  transition: 0.1s;
}

#tb-kontakt-body svg {
  fill: var(--light-grey);
}

#tb-kontakt-body-title {
  font-size: 1.2rem;
  width: 100%;
  font-family: Montserrat;
}

#tb-kontakt-body-close-button {
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  background: transparent;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: transparent;
}

#tb-kontakt-body-close-button::before,
#tb-kontakt-body-close-button::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 20px;
  background: var(--secondary-color);
}

#tb-kontakt-body-close-button::before {
  transform: rotate(45deg);
}

#tb-kontakt-body-close-button::after {
  transform: rotate(-45deg);
}

#tb-kontakt-body-close-button:hover::before,
#tb-kontakt-body-close-button:hover::after,
#tb-kontakt-body-close-button:focus::before,
#tb-kontakt-body-close-button:focus::after {
  background: var(--primary-color);
}

/* MOBILE */

#mobile-top-bar {
  width: 100vw;
  height: 50px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  z-index: 10;
}

#mtb-menu-button {
  border: none;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: relative;
  height: 18px;
  width: 24px;
}

#mtb-menu-button > div {
  width: 24px;
  height: 2px;
  background: #ffffff;
  transition: 0.3s;
  position: absolute;
  left: 0;
}

#mtb-menu-button > div:nth-child(1) {
  top: 0;
}

#mtb-menu-button > div:nth-child(3) {
  bottom: 0;
}

#mtb-menu-list {
  display: flex;
  flex-direction: column;
  background: var(--primary-color);
  width: 100vw;
  position: fixed;
  top: 50px;
  left: 0;
  transition: 0.3s;
  z-index: 9;
  max-height: calc(100vh - 50px);
  overflow: auto;
}

#mtb-menu-list > .menu-tile {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 1px solid #ffffff44;
  background: transparent !important;
}

#mtb-menu-list > .menu-tile > .menu-tile-title {
  padding: 16px;
}

.mtb-tile-elements {
  display: flex;
  flex-direction: column;
  height: 0;
  overflow: hidden;
  transition: 0.2s;
}

#mtb-menu-list > .menu-tile:hover > .mtb-tile-elements {
  height: 260px;
}

#mtb-menu-list > .menu-tile:nth-child(5):hover > .mtb-tile-elements {
  height: 208px;
}

.mtb-tile-elements-title {
  padding: 16px;
  text-decoration: none;
  color: #ffffff;
}

#mtb-background {
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000000aa;
  pointer-events: none;
  opacity: 0;
  transition: 0.3s;
}

#footer-body {
  --border-color: #e6e6e6;
  --text-color: #666666;
  --accent-color: #daad86;

  color: var(--text-color);
  box-sizing: border-box;
  padding: 0 calc((100% - (32.2% + 690px)) / 2);
  line-height: 23px;
  clear: both;
}

#cookies-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--border-color);
  padding: 10px 0;
}

#cookies-info > button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  background: transparent;
  cursor: pointer;
  position: relative;
  border: none;
  outline: transparent;
}

#cookies-info > button::before,
#cookies-info > button::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  background: var(--text-color);
  transform: rotate(45deg);
  z-index: -1;
}

#cookies-info > button:hover::before,
#cookies-info > button:hover::after,
#cookies-info > button:focus::before,
#cookies-info > button:focus::after {
  background: var(--primary-color);
}

#cookies-info > button::before {
  transform: rotate(45deg);
}

#cookies-info > button::after {
  transform: rotate(-45deg);
}

#footer-info {
  border-top: 1px solid var(--border-color);
  padding: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-column {
  max-width: 15%;
  margin-top: 43px;
}

#footer-column-herb {
  margin-top: 66px;
}

@media only screen and (max-width: 1120px) {
  #cookies-info {
    padding: 10px 12px;
    box-sizing: border-box;
  }

  #footer-info {
    flex-direction: column;
    padding: 32px 16px;
    box-sizing: border-box;
  }

  .footer-column {
    width: 100%;
    max-width: 100%;
  }

  #footer-column-herb {
    margin-top: 0px;
  }
}

.footer-column-title {
  color: var(--accent-color);
  font-family: Montserrat;
}

.footer-column-line {
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.footer-column-line > a {
  text-decoration: none;
  color: inherit;
}

.footer-column-line > a:hover {
  text-decoration: underline;
}

.footer-column-line > svg {
  width: 18px;
  height: 19px;
  margin-right: 12px;
  margin-top: 2px;
  fill: var(--text-color);
}

#footer-social-media {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

#footer-social-media > a > svg {
  fill: var(--text-color);
  width: 24px;
  height: 24px;
  padding: 0 2px;
  cursor: pointer;
}

#footer-social-media > a:nth-child(1):hover > svg {
  fill: var(--facebook-color) !important;
}

#footer-social-media > a:nth-child(2):hover > svg {
  fill: var(--instagram-color) !important;
}

#footer-social-media > a:nth-child(3):hover > svg {
  fill: var(--youtube-color) !important;
}

#footer-divider {
  min-width: 1px;
  background: var(--border-color);
}

#footer-bottom {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
}

@media only screen and (max-width: 1120px) {
  #footer-bottom {
    flex-direction: column;
    height: auto;
    padding: 6px 12px;
    box-sizing: border-box;
  }
}

#home-slider {
  width: 100%;
  display: flex;
  height: calc(100% + 38vw);
}

@media only screen and (max-width: 1120px) {
  #home-slider {
    flex-direction: column-reverse;
  }
}

#home-slider-title {
  width: 38vw;
  min-width: 38vw;
  background: var(--primary-color);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  color: #000000;
}

@media only screen and (min-width: 1120px) {
  #home-slider-title {
    padding: 6vw 0 10vw calc((100vw - (32.2vw + 690px)) / 2);
  }
}

#home-slider-title > h1 {
  font-weight: lighter;
}

#home-slider-title-buttons {
  display: flex;
}

@media only screen and (max-width: 1120px) {
  #home-slider-title {
    width: 100vw;
    min-width: 100vw;
    height: auto;
    padding: 24px 0 32px 10vw;
  }

  #home-slider-title > h1 {
    font-size: 24px;
  }

  #home-slider-title-buttons {
    margin-top: 16px;
  }
}

.home-slider-title-button {
  color: inherit;
  border: 1px solid #000000;
  display: flex;
  padding: 16px 24px;
  background: transparent;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  cursor: pointer;
  text-decoration: none;
}

.home-slider-title-button:nth-child(2) {
  margin-left: -1px;
}

.home-slider-title-button:hover,
.home-slider-title-button:focus {
  background: #ffffff;
  color: var(--primary-color) !important;
}

.home-slider-title-button > svg {
  margin-left: 10px;
  fill: #000000;
}

.home-slider-title-button:hover > svg,
.home-slider-title-button:focus > svg {
  fill: var(--primary-color) !important;
}

@media only screen and (max-width: 1120px) {
  .home-slider-title-button {
    padding: 16px 24px;
    font-size: 1rem;
  }
}

#home-slider-image {
  background: var(--light-grey);
  position: relative;
  height: calc(62vw * 0.62);
  width: 62vw;
  z-index: 0;
}

#home-slider-image > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#home-slider-image > div > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
  opacity: 0;
}

@media only screen and (max-width: 1120px) {
  #home-slider-image {
    height: calc(100vw * 0.62);
    width: 100vw;
  }
}

.home-slider-button {
  border: none;
  background: var(--secondary-color);
  width: 70px;
  height: 70px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.home-slider-button:nth-of-type(1) {
  left: 0;
}

.home-slider-button:nth-of-type(2) {
  left: 70px;
  transform: rotate(180deg);
}

@media only screen and (max-width: 1120px) {
  .home-slider-button {
    width: 48px;
    height: 48px;
  }

  .home-slider-button:nth-of-type(2) {
    left: auto;
    right: 0;
  }

  .home-slider-button > svg {
    width: 18px;
  }
}

.home-slider-button > svg {
  fill: #ffffff;
}

.home-slider-button:hover > svg,
.home-slider-button:focus > svg {
  fill: var(--accent-color);
}

/* OBJECTS */

#home-objects {
  background: #ffffff;
  padding: 48px 16px;
}

@media only screen and (min-width: 1120px) {
  #home-objects {
    padding: 100px calc((100% - (32.2% + 690px)) / 2);
  }
}

#home-objects > div:nth-child(1) {
  font-family: Montserrat;
  font-size: 24px;
}

@media only screen and (max-width: 1120px) {
  #home-objects > div:nth-child(1) {
    font-size: 20px;
  }
}

#home-objects-grid {
  margin-top: 36px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(15, 1fr);
  border-bottom: 1px solid var(--light-grey);
  border-right: 1px solid var(--light-grey);
}

@media only screen and (min-width: 1120px) {
  #home-objects-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(5, 1fr);
  }
}

.home-object-tile {
  border-top: 1px solid var(--light-grey);
  border-left: 1px solid var(--light-grey);
  padding: 24px 56px;
  display: flex;
  align-items: center;
  font-size: 1.05rem;
  cursor: pointer;
  color: var(--secondary-color);
  text-decoration: none;
}

@media only screen and (max-width: 1120px) {
  .home-object-tile {
    border-top: 1px solid var(--light-grey);
    border-left: 1px solid var(--light-grey);
    padding: 8px 16px;
    display: flex;
    align-items: center;
    font-size: 1.05rem;
    cursor: pointer;
    color: var(--secondary-color);
    text-decoration: none;
  }
}

.home-object-tile:hover {
  color: #ffffff;
  background: var(--secondary-color);
}

.home-object-tile > svg {
  fill: var(--primary-color);
  margin-right: 24px;
  border: 1px solid var(--primary-color);
  min-width: 30px;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
}

/* MAP */

#home-goto-map {
  width: 100%;
  position: relative;
  overflow: hidden;
}

#map-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1120px) {
  #home-goto-map {
    height: 50vh;
  }
}

#home-goto-map-text {
  padding: 8% 0 13% 20%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  white-space: nowrap;
  z-index: 1;
}

#home-goto-map-label {
  font-size: 64px;
  font-weight: bold;
  font-family: Montserrat;
  line-height: 1.5;
}

#home-goto-map-button {
  border: none;
  background: var(--primary-color);
  color: #ffffff;
  padding: 16px 24px;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.3s;
}

#home-goto-map-button:hover {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

#home-goto-map-button::after {
  content: "";
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  margin-left: 8px;
  display: inline-block;
}

@media only screen and (max-width: 1120px) {
  #home-goto-map-label {
    font-size: 24px;
  }

  #home-goto-map-text {
    padding: 5vw 16px 12vw 10vw;
  }

  #home-goto-map-button {
    font-size: 1rem;
  }

  #home-goto-map-button::after {
    width: 5px;
    height: 5px;
    margin-left: 6px;
  }
}

/* NEWS */

#home-news {
  background: var(--primary-color);
  padding: 100px calc((100% - (32.2% + 690px)) / 2);
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: 1120px) and (min-width: 620px) {
  #home-news {
    padding: 48px 40px;
  }
}

@media only screen and (max-width: 620px) {
  #home-news {
    padding: 48px 16px;
  }
}

#home-news-title-row {
  font-family: Montserrat;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  color: #ffffff;
  font-weight: bold;
}

#home-news-title-row > a {
  background: var(--secondary-color);
  border: none;
  padding: 12px 16px;
  position: relative;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.1rem;
  font-family: Lato;
  font-weight: lighter;
  transition: 0.3s;
}

#home-news-title-row > a:hover {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

#home-news-title-row > a::after {
  content: "";
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  margin-left: 8px;
  display: inline-block;
}

@media only screen and (max-width: 1120px) {
  #home-news-title-row {
    font-size: 20px;
  }

  #home-news-title-row > a {
    padding: 8px 12px;
    font-size: 1rem;
  }

  #home-news-title-row > a::after {
    width: 5px;
    height: 5px;
    margin-left: 6px;
  }
}

#home-news-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  grid-gap: 40px;
  gap: 40px;
  margin: 36px 0 0 0;
}

@media only screen and (max-width: 1120px) and (min-width: 620px) {
  #home-news-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 620px) {
  #home-news-grid {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    gap: 16px;
  }
}

/* WYBUDUJ DOM */

#home-wybuduj-dom {
  background: #ffffff;
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

#home-wd-strug {
  position: absolute;
  right: -200px;
}

#home-wd-rysunek {
  position: absolute;
  right: calc(60vw);
}

#home-wd-text {
  white-space: nowrap;
  z-index: 1;
  position: absolute;
  left: 45vw;
}

@media only screen and (max-width: 730px) {
  #home-wd-strug {
    right: -350px;
    bottom: -50px;
  }

  #home-wd-rysunek {
    opacity: 0;
  }

  #home-wd-text {
    left: 10vw;
  }
}

#home-wd-label {
  font-size: 64px;
  font-weight: bold;
  font-family: Montserrat;
  line-height: 1.5;
}

#home-wd-button {
  border: none;
  background: var(--accent-color);
  color: #ffffff;
  padding: 16px 24px;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 56px;
  text-decoration: none;
  transition: 0.3s;
  display: inline-block;
}

#home-wd-button:hover {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

#home-wd-button::after {
  content: "";
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  margin-left: 8px;
  display: inline-block;
}

@media only screen and (max-width: 1120px) {
  #home-wybuduj-dom {
    height: 50vh;
  }

  #home-wd-label {
    font-size: 24px;
  }

  #home-wd-button {
    font-size: 1rem;
  }

  #home-wd-button::after {
    width: 5px;
    height: 5px;
    margin-left: 6px;
  }
}

/* INSTAGRAM */

#home-instagram {
  background: var(--secondary-color);
  padding: 100px calc((100% - (32.2% + 690px)) / 2);
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

#home-instagram-photos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 40px;
  gap: 40px;
  margin-top: 36px;
}

@media only screen and (max-width: 1120px) and (min-width: 620px) {
  #home-instagram {
    padding: 48px 40px;
  }

  #home-instagram-photos {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 620px) {
  #home-instagram {
    padding: 48px 16px;
  }

  #home-instagram-photos {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    gap: 16px;
  }
}

#home-instagram-photos > div {
  width: 100%;
  padding-top: 100%;
  background: var(--primary-color);
}

/* CONTACT */

#home-contact-form {
  background: #ffffff;
  padding: 100px calc((100% - (32.2% + 690px)) / 2);
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

#home-contact-form-label {
  font-size: 24px;
  font-family: Montserrat;
}

@media only screen and (max-width: 1120px) {
  #home-contact-form {
    padding: 48px 16px;
  }

  #home-contact-form-label {
    font-size: 20px;
  }
}

#home-contact-form-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  gap: 30px;
  margin-top: 36px;
}

.home-contact-form-input-wrapper {
  position: relative;
}

.home-contact-form-input-wrapper:nth-child(3) {
  align-self: center;
}

.home-contact-form-input-wrapper > input,
.home-contact-form-input-wrapper > textarea {
  background: transparent;
  font-family: Lato;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 12px;
  border: 1px solid var(--light-grey);
  font-size: 1.1rem;
}

.home-contact-form-input-wrapper > textarea {
  height: 100%;
  resize: vertical;
  min-height: 108px;
}

.home-contact-form-input-wrapper > label {
  position: absolute;
  padding: 0px 4px;
  top: 8px;
  left: 8px;
  font-size: 1.1rem;
  color: var(--light-grey);
  background: #ffffff;
  transition: 0.2s;
}

.home-contact-form-input-wrapper > label {
  top: -8px;
  font-size: 0.8rem;
}

.home-contact-form-input-wrapper:nth-child(4) {
  grid-column: 2 / 4;
  grid-row: 1 / 3;
}

@media only screen and (max-width: 1120px) {
  #home-contact-form-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .home-contact-form-input-wrapper:nth-child(4) {
    grid-column: auto;
    grid-row: auto;
  }
}

#home-contact-form-captcha-wrapper {
  justify-self: center;
  border: 2px solid transparent;
}

#home-contact-form-send {
  border: none;
  background: var(--secondary-color);
  width: 100%;
  height: 47px;
  color: #ffffff;
  font-size: 1.1rem;
  font-family: Montserrat;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  align-self: center;
}

@media only screen and (max-width: 1120px) {
  #home-contact-form-send {
    padding: 16px 0;
  }
}

#home-contact-form-send > svg {
  fill: var(--accent-color);
  margin-left: 100px;
  position: absolute;
  transition: 0.2s;
  animation: 0.3s unsendAnimation forwards;
}

#home-contact-form-send:hover > svg,
#home-contact-form-send:focus > svg {
  animation: 0.3s sendAnimation forwards;
}

@keyframes sendAnimation {
  0% {
    transform: translate(0px, -0px);
  }
  100% {
    transform: translate(30px, -30px);
  }
}

@keyframes unsendAnimation {
  0% {
    transform: translate(-30px, 30px);
  }
  100% {
    transform: translate(0px, -0px);
  }
}

#home-contact-form-email-sent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-size: 40px;
  letter-spacing: 3px;
  z-index: 1;
  overflow: hidden;
  transition: 0.3s;
}

#home-contact-form-email-sent > div {
  position: absolute;
  top: 25%;
  opacity: 0;
  transition: top 3s, opacity 2s 1s;
}

#home-contact-form-email-sent > svg {
  position: absolute;
  width: 200px;
  height: 200px;
  opacity: 0.4;
  fill: var(--accent-color);
  z-index: -1;
  margin-left: 400px;
  margin-top: 100px;
}

@keyframes sentEmail {
  0% {
    margin-left: -600px;
    margin-top: -400px;
    transform: rotate(90deg);
  }
  30% {
    margin-left: 0;
    margin-top: 500px;
    transform: rotate(90deg);
  }
  31% {
    transform: rotate(0deg);
  }
  100% {
    margin-left: 400px;
    margin-top: 100px;
    transform: rotate(0deg);
  }
}

.news-tile {
  background: white;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  text-decoration: none;
  color: var(--text-color);
}

.news-tile:hover,
.news-tile:focus {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.news-tile-image {
  width: 100%;
  padding-top: 62%;
  background: var(--light-grey);
  position: relative;
}

.news-tile-image > div {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.news-tile-image > div > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.news-tile:hover img {
  transform: scale(1.1);
}

.news-tile-date {
  color: var(--accent-color);
  font-size: 0.9rem;
  margin: 16px 0 0 12px;
}

.news-tile-title {
  font-weight: bold;
  margin: 16px 0 16px 12px;
  font-size: 1.1rem;
}

.news-tile-opis {
  margin: 12px 0 16px 12px;
  font-size: 0.95rem;
}

.news-tile-read-more {
  color: var(--accent-color);
  font-size: 0.9rem;
  margin: 16px 0 24px 12px;
}

#objects-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 100px;
}

.objects-filters-button {
  cursor: pointer;
  border: none;
  color: #000000;
  border: 1px solid var(--secondary-color);
  padding: 12px 18px;
  width: 100px;
  font-size: 1rem;
}

.objects-filters-button:hover,
.objects-filters-button:focus {
  background: var(--secondary-color);
  color: #ffffff;
}

#objects-filters-filter-button {
  width: 200px;
  background: var(--secondary-color);
  color: #ffffff;
  margin-left: calc(100% - 200px);
  border: none;
}

#objects-filters-filter-button:hover,
#objects-filters-filter-button:focus {
  background: var(--primary-color);
}

#objects-filters-body {
  border: 1px solid var(--secondary-color);
  width: 100%;
  margin: 40px 0;
  box-sizing: border-box;
  padding: 16px;
}

.objects-filters-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 12px;
}

.objects-filters-rodzaj-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.objects-filters-rodzaj-body input {
  width: 0;
  height: 0;
  pointer-events: none;
  position: absolute;
  opacity: 0;
}

.objects-filters-rodzaj-body input ~ label {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 0 16px 24px 16px;
}

.objects-filters-rodzaj-body input ~ label:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid var(--primary-color);
  margin-right: 8px;
}

.objects-filters-rodzaj-body input:focus ~ label {
  text-decoration: underline;
}

.objects-filters-rodzaj-body input:checked ~ label:before {
  background: var(--primary-color);
}

.objects-filters-rodzaj-body input:checked ~ label:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 6px;
  bottom: 6px;
  width: 6px;
  height: 12px;
  transform: rotate(45deg);
  border-bottom: 2px solid #f6f6f6;
  border-right: 2px solid #f6f6f6;
  opacity: 1 !important;
}

.objects-filters-input {
  width: calc(100% - 32px);
  max-width: 500px;
  margin: 24px 16px;
}

#objects-content {
  padding: 40px 0;
}

#object-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  grid-gap: 40px;
  gap: 40px;
}

#objects-button-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1120px) {
  #object-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 32px;
    gap: 32px;
  }
}

@media only screen and (max-width: 750px) {
  #object-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    gap: 32px;
  }
}

@media only screen and (max-width: 460px) {
  #objects-content {
    padding: 32px 0;
  }

  #object-grid {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    gap: 16px;
  }
}

.object-tile {
  background: white;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  text-decoration: none;
  color: var(--text-color);
}

.object-tile:hover,
.object-tile:focus {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.object-tile-image {
  width: 100%;
  padding-top: 75%;
  position: relative;
  background: var(--light-grey);
}

.object-tile-image > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.object-tile-image > div > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.object-tile:hover img {
  transform: scale(1.1);
}

.object-tile-name {
  font-weight: bold;
  margin: 16px 0 0 12px;
  font-size: 1.05rem;
}

.object-tile-location {
  margin: 12px 0 16px 12px;
}

#o-image-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

#o-image-row > div {
  height: 160px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
  cursor: pointer;
}

#o-image-row > div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: #00000088;
  pointer-events: none;
}

#o-image-row > div:hover::after {
  opacity: 1;
}

#o-image-row img {
  max-height: 160px;
  height: 160px;
}

#o-image-row svg {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  width: 32px;
  height: 32px;
  z-index: 1;
}

#o-image-row > div:hover > svg {
  opacity: 1;
}

#o-miejscowosc {
  font-size: 20px;
  margin: 30px 0 40px 0;
}

#o-rodzaj, #o-data, #o-zabytek {
  line-height: 1.7;
  font-size: 16px;
}

#o-icon-row {
  margin: 60px 0;
  width: 100%;
  height: 1px;
  background: var(--accent-color);
}

#o-mapa {
  width: 100vw;
  height: calc(400px + 20vw);
  background: var(--light-grey);
  position: relative;
}
#a-img {
  margin: 100px 0;
  width: calc(var(--content-width) * 0.8);
  height: calc(var(--content-width) * 0.8 * 0.62);
  background: var(--light-grey);
  position: relative;
}

#a-img > div,
#a-img > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#a-img > div > img,
#a-img > a > img {
  object-fit: cover;
  height: 100%;
  transition: 0.3s;
}

#a-img-label {
  font-size: 0.9rem;
  bottom: -1rem;
  position: absolute;
  left: 0;
  font-style: italic;
}

.a-img-change-button {
  position: absolute;
  height: 100%;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  top: 0;
  left: -10%;
  background: transparent;
  border: none;
}

.a-img-change-button:last-child {
  left: auto;
  right: -10%;
}

.a-img-change-button:hover {
  background: #00000044;
}

.a-img-change-button::after {
  content: "";
  position: absolute;
  border-left: 2px solid var(--secondary-color);
  border-top: 2px solid var(--secondary-color);
  transform: rotate(-45deg);
  pointer-events: none;
  width: 2vw;
  height: 2vw;
  margin-left: 1vw;
}

.a-img-change-button:last-child::after {
  transform: rotate(135deg);
  margin-left: -1vw;
}

#a-date {
  font-size: 15px;
  margin-bottom: 40px;
  color: var(--accent-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 1120px) {
  #a-img {
    margin: 32px 0;
    width: calc((100vw - 64px));
    height: calc((100vw - 64px) * 0.62);
    max-width: 800px;
    max-height: calc(800px * 0.62);
  }

  #a-date {
    margin-bottom: 32px;
  }
}

#a-title-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.a-title-row-button {
  font-size: 1rem;
  background: transparent;
  padding: 6px 12px;
  /* border: 1px solid var(--secondary-color); */
  cursor: pointer;
  color: var(--secondary-color);
  text-decoration: none;
}

.a-title-row-button:hover {
  /* background: var(--secondary-color); */
  /* color: white; */
  text-decoration: underline;
}

#a-date > svg {
  fill: var(--accent-color);
  margin-right: 5px;
  margin-top: -2px;
  width: 16px;
  height: 16px;
}

#a-divider {
  margin: 72px 0;
  height: 1px;
  width: 20%;
  background: var(--light-grey);
}

@media only screen and (max-width: 1120px) {
  #a-divider {
    margin: 32px 0;
    height: 1px;
    width: 50%;
  }
}

#a-content {
  width: 800px;
  text-align: start;
}

#a-content * {
  font-family: Lato !important;
  font-size: 1rem !important;
}

#a-content img {
  object-fit: cover;
  width: 100%;
}

#a-content iframe {
  border: none;
  width: 100%;
}

#a-buttons {
  margin: 100px 0 120px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(var(--content-width) * 0.8);
}

#a-buttons > a:nth-of-type(1):hover {
  background: var(--accent-color) !important;
}

@media only screen and (max-width: 1120px) {
  #a-content {
    width: 100%;
    max-width: 800px;
  }

  #a-buttons {
    max-width: 800px;
    margin: 32px 0 48px 0;
    width: 100%;
  }
}

.big-news-tile {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  margin-top: 40px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  text-decoration: none;
  color: var(--text-color);
  height: calc(var(--content-width) * 0.4 * 0.62 - 1px);
  overflow: hidden;
}

.big-news-tile:nth-child(odd) {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.big-news-tile:hover,
.big-news-tile:focus {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.big-news-tile-content {
  padding: 40px;
  width: 60%;
}

.big-news-tile-title {
  font-size: 21px;
  font-weight: 600;
  font-family: Montserrat;
  margin-bottom: 20px;
}

.big-news-tile-date {
  font-size: 15px;
  margin-bottom: 30px;
  color: var(--accent-color);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.big-news-tile-date > svg {
  fill: var(--accent-color);
  margin-right: 5px;
  margin-top: -2px;
  width: 16px;
  height: 16px;
}

.big-news-tile-desc {
  overflow: hidden;
  height: calc(var(--content-width) * 0.4 * 0.62 - 80px - 45px - 48px);
  max-height: calc(var(--content-width) * 0.4 * 0.62 - 80px - 45px - 48px);
  position: relative;
}

.big-news-tile-desc::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, transparent, #ffffff);
}

.big-news-tile-desc * {
  font-family: Lato !important;
  font-size: 1rem !important;
}

.big-news-tile-image {
  width: 40%;
  height: calc(var(--content-width) * 0.4 * 0.62);
  background: var(--light-grey);
  position: relative;
}

.big-news-tile-image > div {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.big-news-tile-image > div > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.big-news-tile:hover img {
  transform: scale(1.1);
}

#news-more {
  margin: 70px 0 0 calc(50% - 85px);
  border: none;
  background: var(--secondary-color);
  color: #ffffff;
  font-family: Montserrat;
  font-size: 1rem;
  padding: 18px 34px;
  cursor: pointer;
}

#news-more:hover,
#news-more:focus {
  color: var(--accent-color);
}

@media only screen and (min-width: 550px) and (max-width: 1120px) {
  .big-news-tile {
    height: auto;
  }
  
  .big-news-tile:nth-child(even) {
    margin-top: 16px;
    flex-direction: column-reverse;
    float: right;
    width: calc(50% - 8px);
  }

  .big-news-tile:nth-child(odd) {
    margin-top: 16px;
    flex-direction: column-reverse;
    float: left;
    width: calc(50% - 8px);
  }

  .big-news-tile-content {
    padding: 16px 16px 24px;
    width: 100%;
  }

  .big-news-tile-title {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .big-news-tile-date {
    font-size: 14px;
    margin-bottom: 18px;
  }

  .big-news-tile-image {
    width: 100%;
    height: calc((50vw - 24px) * 0.62);
  }

  #news-more {
    margin: 40px 0 0 calc(50% - 85px);
  }
}

@media only screen and (max-width: 550px) {
  .big-news-tile {
    height: auto;
  }

  .big-news-tile:nth-child(2) {
    margin-top: 32px !important;
  }

  .big-news-tile {
    margin-top: 16px;
    flex-direction: column-reverse !important;
  }

  .big-news-tile-content {
    padding: 16px 16px 24px;
    width: 100%;
  }

  .big-news-tile-title {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .big-news-tile-date {
    font-size: 14px;
    margin-bottom: 18px;
  }

  .big-news-tile-image {
    width: 100%;
    height: calc((100vw - 32px) * 0.62);
  }

  #news-more {
    margin: 40px 0 0 calc(50% - 85px);
  }
}

#news-filters-sort-button {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1rem;
  padding: 6px 12px;
  border-radius: 3px;
  margin-left: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.2s;
  outline: transparent;
}

#news-filters-sort-button:hover,
#news-filters-sort-button:focus {
  background: var(--light-grey);
}

#news-filters-sort-button > div {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

#news-filters-sort-button:hover > div::before,
#news-filters-sort-button:focus > div::before {
  transform: rotate(-180deg);
}

#news-filters-sort-button:hover > div::after,
#news-filters-sort-button:focus > div::after {
  transform: rotate(-135deg);
}

#news-filters-sort-button > div::before {
  content: "";
  position: absolute;
  width: 2px;
  height: 14px;
  background: black;
  transition: 0.2s;
}

#news-filters-sort-button > div::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(45deg);
  transition: 0.2s;
}

#historia-content {
  width: 1000px;
  display: flex;
  flex-direction: row;
  margin-bottom: 120px;
  align-items: center;
}

#historia-images {
  display: flex;
  flex-direction: column;
}

.historia-image {
  width: calc(1000px * 0.4);
  /* height: calc(1000px * 0.4 * 0.62); */
  margin-bottom: 120px;
  overflow: hidden;
}

.historia-image > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#historia-desc {
  margin-left: 60px;
  line-height: 1.5;
}

@media only screen and (max-width: 1120px) {
  #historia-content {
    width: calc(100vw - 32px);
    flex-direction: column;
    margin-bottom: 48px;
    padding: 0 16px;
    position: relative;
  }

  #historia-images {
    flex-direction: row;
  }

  .historia-image {
    width: calc((100vw - 32px) / 3 - 16px);
    height: calc(((100vw - 32px) / 3 - 16px) * 0.62);
    margin: 0 8px;
    margin-bottom: 24px;
  }

  .historia-image:last-child {
    display: none;
  }

  #historia-desc {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 1120px) and (min-width: 770px) {
  .historia-image {
    width: calc((100vw - 32px) / 3 - 16px);
    height: calc(((100vw - 32px) / 3 - 16px) * 0.62);
    margin: 0 8px;
    margin-bottom: 24px;
  }

  .historia-image:last-child {
    display: none;
  }
}

@media only screen and (max-width: 770px) and (min-width: 520px) {
  #historia-content {
    padding-bottom: calc(((100vw - 32px) / 2 - 16px) * 0.62 + 24px);
  }

  .historia-image {
    width: calc((100vw - 32px) / 2 - 16px);
    height: calc(((100vw - 32px) / 2 - 16px) * 0.62);
    margin: 0 8px;
    margin-bottom: 24px;
  }

  .historia-image:nth-child(3),
  .historia-image:last-child {
    display: block;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }

  .historia-image:last-child {
    right: 16px;
  }
}

@media only screen and (max-width: 520px) {
  #historia-content {
    padding-bottom: calc(((100vw - 32px) - 16px) * 0.62 + 24px);
  }

  .historia-image {
    width: calc((100vw - 32px) - 16px);
    height: calc(((100vw - 32px) - 16px) * 0.62);
    margin: 0 8px;
    margin-bottom: 24px;
  }

  .historia-image:nth-child(2) {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }

  .historia-image:nth-child(3),
  .historia-image:last-child {
    display: none;
  }
}

#mapa-body {
  background: var(--primary-color);
  width: 100%;
  height: calc(100vh - 50px);
  position: absolute;
  top: 50px;
}

#mapa-body a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

#mapa-body a:hover {
  text-decoration: underline;
}

#map-filters-button {
  border: none;
  outline: transparent;
  position: absolute;
  top: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.3s;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  left: 203px;
  width: 150px;
  max-width: calc(100vw - 213px);
}

#map-filters-button:hover,
#map-filters-button:focus {
  background: rgb(235, 235, 235);
}

#map-filters-body {
  width: 400px;
  background: #ffffff;
  border-radius: 5px;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  height: 100%;
  transform: translateX(-400px);
  position: relative;
  max-width: 100vw;
}

#map-filters-body > div {
  overflow: auto;
  height: calc(100%);
  width: 100%;
  padding: 32px 16px 0 16px;
  box-sizing: border-box;
  position: relative;
}

#map-filters-body::after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  height: 125px;
  background: linear-gradient(transparent 0%, #ffffff 25%);
}

.close-map-filters-button {
  position: absolute;
  right: 32px;
  top: 16px;
  background: #ffffff;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.close-map-filters-button::before,
.close-map-filters-button::after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 100%;
  position: absolute;
  transform: rotate(45deg);
  background: var(--text-color);
}

.close-map-filters-button::before {
  transform: rotate(45deg);
}

.close-map-filters-button::after {
  transform: rotate(-45deg);
}

.map-filters-save-button {
  position: absolute !important;
  bottom: 16px;
  left: -184px;
  z-index: 1 !important;
  width: 368px !important;
  height: 43px;
}

@media only screen and (max-width: 400px) {
  .map-filters-save-button {
    left: auto;
    right: 16px;
    width: calc(100vw - 32px) !important;
  }
}

#map-object-info-modal {
  width: 400px;
  background: #ffffff;
  border-radius: 5px;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  height: 100%;
  transform: translateX(-400px);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
}

.close-map-object-info {
  right: 16px;
  top: 16px;
  background: transparent;
  border-radius: 100%;
  transition: 0.1s;
  outline: transparent;
}

.close-map-object-info:hover,
.close-map-object-info:focus {
  background: #ffffff;
  box-shadow: 0px 0px 5px 5px #ffffff;
}

#map-object-info-photo {
  width: 100%;
  height: 400px;
  min-height: 400px;
  background: grey;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

#map-object-info-photo > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

#map-object-info-modal > h2 {
  font-family: Montserrat;
  padding: 16px;
  margin: 0;
}

#o-rodzaj,
#o-data,
#o-zabytek {
  padding: 0 16px;
}

#map-object-info-description {
  padding: 16px;
  max-height: 100%;
  position: relative;
  overflow: hidden;
}

#map-object-info-description * {
  font-family: Lato !important;
  font-size: 1rem !important;
  background: transparent !important;
}

#map-object-info-description::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3rem;
  bottom: 0;
  left: 0;
  background: linear-gradient(transparent 0%, #fff 85%);
}

#map-object-info-link {
  margin: 16px;
  text-align: end;
}

.crafter-tile-location {
  margin: 12px 0 12px 12px;
  display: flex;
  flex-direction: row;
  line-height: 1.4;
}

.crafter-tile-location > svg {
  fill: var(--accent-color);
  margin: 4px 6px 0 0;
}

.crafter-tile-speciality {
  margin: 0px 0 16px 12px;
  display: flex;
  flex-direction: row;
}

.crafter-tile-speciality > svg {
    margin: 3px 6px 0 0;
}

#projekt-body {
  background: #ffffff;
  width: 100%;
  height: calc(100vh - 50px);
  position: absolute;
  top: 50px;
  overflow: hidden;
}

#projekt-setup-location {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10vh 0;
}

.projekt-select {
  font-size: inherit;
  font-family: inherit;
  border: none;
  cursor: pointer;
  border-bottom: 2px solid var(--primary-color);
  padding: 5px 3px;
  outline: transparent;
}

.projekt-button {
  border: none;
  background: var(--primary-color);
  margin-top: 10vh;
  padding: 0 108px;
  height: 50px;
  font-size: inherit;
  font-family: Montserrat;
  cursor: pointer;
  color: #ffffff;
  transition: 0.2s;
  border-radius: 30px;
  outline: transparent;
}

.projekt-button:hover,
.projekt-button:focus {
  background: var(--secondary-color);
}

#projekt-house-model {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

#phm-options {
  position: absolute;
  height: 100%;
  left: 0;
  width: 400px;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 5vh 0;
  z-index: 1;
}

@media (max-width: 400px) {
  #phm-options {
    width: 100vw;
  }
}

#phm-options-button {
  border: none;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  cursor: pointer;
  width: 50px;
  background: transparent;
  outline: transparent;
}

#phm-options-button-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--light-grey);
  top: 0;
  left: 0 !important;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.2s;
}

#phm-options-button:hover > #phm-options-button-background {
  transform: scaleX(1);
}

#phm-options-button-arrow {
  width: 20px;
  height: 20px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(-45deg);
  margin-left: 12px;
  transition: 0.3s;
}

.phm-options-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 82px 0 32px;
}

.phm-options-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 12px;
  margin-top: 24px;
}

.phm-options-section:nth-child(1) > .phm-options-title {
  margin-top: 0;
}

.phm-options-section label {
  font-size: 1.05rem;
  margin: 12px 0 0 12px;
  font-weight: bold;
}

.phm-options-section label:nth-child(1) {
  margin: 0 0 0 12px;
}

.phm-options-section label ~ select {
  margin-left: 24px !important;
}

#phm-options-colors-grid {
  width: 100%;
  box-sizing: border-box;
  padding-left: 12px;
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-gap: 12px;
  gap: 12px;
  grid-column-gap: 24px;
  -webkit-column-gap: 24px;
          column-gap: 24px;
}

.phm-options-color {
  width: 38px;
  height: 38px;
  background: blue;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.1s;
  border: 1px solid var(--secondary-color);
}

.phm-options-color:hover {
  box-shadow: 0px 4px 8px 0px #00000044;
}

#house-model {
  position: fixed;
  width: calc(100% - 50px);
  height: 100%;
  right: 0;
  top: 50px;
  overflow: hidden;
  transform: translateX(175px);
}

#house-project {
  position: absolute;
  width: calc(100% - 50px);
  height: 100%;
  right: -350px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

#house-project > img {
  width: 60%;
  transition: 0.1s width;
}

#phm-options > .projekt-button {
  position: absolute;
  bottom: 5vh;
}

#phm-loading {
  position: absolute;
  pointer-events: none;
  left: calc(50% + 50px);
  top: calc(50% - 20px);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--primary-color);
  transform: rotate(0);
  animation: rotate 0.5s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

#phm-loading::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background: white;
  top: 12px;
  left: -10px;
  transform: rotate(45deg);
}

#phm-loading::after {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 50%;
  top: 8px;
  left: 8px;
}

