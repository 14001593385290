#projekt-body {
  background: #ffffff;
  width: 100%;
  height: calc(100vh - 50px);
  position: absolute;
  top: 50px;
  overflow: hidden;
}

#projekt-setup-location {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10vh 0;
}

.projekt-select {
  font-size: inherit;
  font-family: inherit;
  border: none;
  cursor: pointer;
  border-bottom: 2px solid var(--primary-color);
  padding: 5px 3px;
  outline: transparent;
}

.projekt-button {
  border: none;
  background: var(--primary-color);
  margin-top: 10vh;
  padding: 0 108px;
  height: 50px;
  font-size: inherit;
  font-family: Montserrat;
  cursor: pointer;
  color: #ffffff;
  transition: 0.2s;
  border-radius: 30px;
  outline: transparent;
}

.projekt-button:hover,
.projekt-button:focus {
  background: var(--secondary-color);
}

#projekt-house-model {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

#phm-options {
  position: absolute;
  height: 100%;
  left: 0;
  width: 400px;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 5vh 0;
  z-index: 1;
}

@media (max-width: 400px) {
  #phm-options {
    width: 100vw;
  }
}

#phm-options-button {
  border: none;
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  cursor: pointer;
  width: 50px;
  background: transparent;
  outline: transparent;
}

#phm-options-button-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--light-grey);
  top: 0;
  left: 0 !important;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.2s;
}

#phm-options-button:hover > #phm-options-button-background {
  transform: scaleX(1);
}

#phm-options-button-arrow {
  width: 20px;
  height: 20px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  transform: rotate(-45deg);
  margin-left: 12px;
  transition: 0.3s;
}

.phm-options-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 82px 0 32px;
}

.phm-options-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 12px;
  margin-top: 24px;
}

.phm-options-section:nth-child(1) > .phm-options-title {
  margin-top: 0;
}

.phm-options-section label {
  font-size: 1.05rem;
  margin: 12px 0 0 12px;
  font-weight: bold;
}

.phm-options-section label:nth-child(1) {
  margin: 0 0 0 12px;
}

.phm-options-section label ~ select {
  margin-left: 24px !important;
}

#phm-options-colors-grid {
  width: 100%;
  box-sizing: border-box;
  padding-left: 12px;
  display: grid;
  grid-template-columns: repeat(5, 40px);
  gap: 12px;
  column-gap: 24px;
}

.phm-options-color {
  width: 38px;
  height: 38px;
  background: blue;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.1s;
  border: 1px solid var(--secondary-color);
}

.phm-options-color:hover {
  box-shadow: 0px 4px 8px 0px #00000044;
}

#house-model {
  position: fixed;
  width: calc(100% - 50px);
  height: 100%;
  right: 0;
  top: 50px;
  overflow: hidden;
  transform: translateX(175px);
}

#house-project {
  position: absolute;
  width: calc(100% - 50px);
  height: 100%;
  right: -350px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

#house-project > img {
  width: 60%;
  transition: 0.1s width;
}

#phm-options > .projekt-button {
  position: absolute;
  bottom: 5vh;
}

#phm-loading {
  position: absolute;
  pointer-events: none;
  left: calc(50% + 50px);
  top: calc(50% - 20px);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--primary-color);
  transform: rotate(0);
  animation: rotate 0.5s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

#phm-loading::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background: white;
  top: 12px;
  left: -10px;
  transform: rotate(45deg);
}

#phm-loading::after {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 50%;
  top: 8px;
  left: 8px;
}
