.object-tile {
  background: white;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  text-decoration: none;
  color: var(--text-color);
}

.object-tile:hover,
.object-tile:focus {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.object-tile-image {
  width: 100%;
  padding-top: 75%;
  position: relative;
  background: var(--light-grey);
}

.object-tile-image > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.object-tile-image > div > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.object-tile:hover img {
  transform: scale(1.1);
}

.object-tile-name {
  font-weight: bold;
  margin: 16px 0 0 12px;
  font-size: 1.05rem;
}

.object-tile-location {
  margin: 12px 0 16px 12px;
}
