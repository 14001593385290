#objects-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 100px;
}

.objects-filters-button {
  cursor: pointer;
  border: none;
  color: #000000;
  border: 1px solid var(--secondary-color);
  padding: 12px 18px;
  width: 100px;
  font-size: 1rem;
}

.objects-filters-button:hover,
.objects-filters-button:focus {
  background: var(--secondary-color);
  color: #ffffff;
}

#objects-filters-filter-button {
  width: 200px;
  background: var(--secondary-color);
  color: #ffffff;
  margin-left: calc(100% - 200px);
  border: none;
}

#objects-filters-filter-button:hover,
#objects-filters-filter-button:focus {
  background: var(--primary-color);
}

#objects-filters-body {
  border: 1px solid var(--secondary-color);
  width: 100%;
  margin: 40px 0;
  box-sizing: border-box;
  padding: 16px;
}

.objects-filters-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 12px;
}

.objects-filters-rodzaj-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.objects-filters-rodzaj-body input {
  width: 0;
  height: 0;
  pointer-events: none;
  position: absolute;
  opacity: 0;
}

.objects-filters-rodzaj-body input ~ label {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 0 16px 24px 16px;
}

.objects-filters-rodzaj-body input ~ label:before {
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid var(--primary-color);
  margin-right: 8px;
}

.objects-filters-rodzaj-body input:focus ~ label {
  text-decoration: underline;
}

.objects-filters-rodzaj-body input:checked ~ label:before {
  background: var(--primary-color);
}

.objects-filters-rodzaj-body input:checked ~ label:after {
  content: "";
  display: inline-block;
  position: absolute;
  left: 6px;
  bottom: 6px;
  width: 6px;
  height: 12px;
  transform: rotate(45deg);
  border-bottom: 2px solid #f6f6f6;
  border-right: 2px solid #f6f6f6;
  opacity: 1 !important;
}

.objects-filters-input {
  width: calc(100% - 32px);
  max-width: 500px;
  margin: 24px 16px;
}

#objects-content {
  padding: 40px 0;
}

#object-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 40px;
}

#objects-button-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1120px) {
  #object-grid {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
  }
}

@media only screen and (max-width: 750px) {
  #object-grid {
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }
}

@media only screen and (max-width: 460px) {
  #objects-content {
    padding: 32px 0;
  }

  #object-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
