@import url("https://fonts.googleapis.com/css?family=Lato|Montserrat:500&display=swap");

body {
  --primary-color: #2e9dae;
  --secondary-color: #312c32;
  --accent-color: #daad86;
  --light-grey: #666666;
  --text-color: #03080b;
  --facebook-color: #4267b2;
  --instagram-color: #fbad50;
  --youtube-color: rgb(248, 0, 0);
  --content-width: calc(32.2vw + 690px);

  margin: 0;
  font-family: "Lato";
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 1120px) {
  body {
    --content-width: calc(100vw - 32px);
  }
}

#a-content a {
  color: var(--text-color);
}

#a-content a:hover {
  color: var(--primary-color);
}

button {
  font-family: Lato;
  color: var(--text-color);
}

#content {
  background: #f6f6f6;
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (min-width: 1120px) {
  #content {
    padding: 0 calc((100% - (32.2% + 690px)) / 2);
  }
}

@media only screen and (max-width: 1120px) {
  #content {
    padding: 0 16px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  font-family: Montserrat;
}

.title-top {
  padding-top: 100px;
}

#a-title {
  text-align: center;
  width: 800px;
  font-size: 27px;
  font-family: Montserrat;
  font-weight: 700;
}

@media only screen and (max-width: 1120px) {
  .title {
    font-size: 20px;
  }

  .title-top {
    padding-top: 32px;
  }

  #a-title {
    width: 100%;
    font-size: 20px;
  }
}

.square-button {
  background: transparent;
  border: 1px solid var(--secondary-color);
  fill: var(--secondary-color);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s;
}

.square-button:hover {
  color: #ffffff;
  background: var(--secondary-color);
}

.square-button > svg {
  fill: inherit;
}

.square-button:hover > svg {
  fill: #ffffff;
}

.square-button-dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--secondary-color);
  margin: 0 2px;
}

#loading {
  width: 100%;
  text-align: center;
  padding: 36px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  --animation-duration: 0.6s;
}

#loading > div {
  width: 15px;
  height: 15px;
  margin: 0 4px;
  border-radius: 50%;
  background: var(--primary-color);
}

#loading > div:nth-child(1) {
  animation: var(--animation-duration) loadingAnim0 infinite ease-out;
}

@keyframes loadingAnim0 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1) translateX(0);
  }
  100% {
    transform: translateX(23px);
  }
}

#loading > div:nth-child(2) {
  animation: var(--animation-duration) loadingAnim1 infinite ease-out;
}

@keyframes loadingAnim1 {
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(23px);
  }
}

#loading > div:nth-child(3) {
  animation: var(--animation-duration) loadingAnim2 infinite ease-out;
}

@keyframes loadingAnim2 {
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
