.crafter-tile-location {
  margin: 12px 0 12px 12px;
  display: flex;
  flex-direction: row;
  line-height: 1.4;
}

.crafter-tile-location > svg {
  fill: var(--accent-color);
  margin: 4px 6px 0 0;
}

.crafter-tile-speciality {
  margin: 0px 0 16px 12px;
  display: flex;
  flex-direction: row;
}

.crafter-tile-speciality > svg {
    margin: 3px 6px 0 0;
}
