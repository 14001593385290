#home-slider {
  width: 100%;
  display: flex;
  height: calc(100% + 38vw);
}

@media only screen and (max-width: 1120px) {
  #home-slider {
    flex-direction: column-reverse;
  }
}

#home-slider-title {
  width: 38vw;
  min-width: 38vw;
  background: var(--primary-color);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  color: #000000;
}

@media only screen and (min-width: 1120px) {
  #home-slider-title {
    padding: 6vw 0 10vw calc((100vw - (32.2vw + 690px)) / 2);
  }
}

#home-slider-title > h1 {
  font-weight: lighter;
}

#home-slider-title-buttons {
  display: flex;
}

@media only screen and (max-width: 1120px) {
  #home-slider-title {
    width: 100vw;
    min-width: 100vw;
    height: auto;
    padding: 24px 0 32px 10vw;
  }

  #home-slider-title > h1 {
    font-size: 24px;
  }

  #home-slider-title-buttons {
    margin-top: 16px;
  }
}

.home-slider-title-button {
  color: inherit;
  border: 1px solid #000000;
  display: flex;
  padding: 16px 24px;
  background: transparent;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  cursor: pointer;
  text-decoration: none;
}

.home-slider-title-button:nth-child(2) {
  margin-left: -1px;
}

.home-slider-title-button:hover,
.home-slider-title-button:focus {
  background: #ffffff;
  color: var(--primary-color) !important;
}

.home-slider-title-button > svg {
  margin-left: 10px;
  fill: #000000;
}

.home-slider-title-button:hover > svg,
.home-slider-title-button:focus > svg {
  fill: var(--primary-color) !important;
}

@media only screen and (max-width: 1120px) {
  .home-slider-title-button {
    padding: 16px 24px;
    font-size: 1rem;
  }
}

#home-slider-image {
  background: var(--light-grey);
  position: relative;
  height: calc(62vw * 0.62);
  width: 62vw;
  z-index: 0;
}

#home-slider-image > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#home-slider-image > div > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
  opacity: 0;
}

@media only screen and (max-width: 1120px) {
  #home-slider-image {
    height: calc(100vw * 0.62);
    width: 100vw;
  }
}

.home-slider-button {
  border: none;
  background: var(--secondary-color);
  width: 70px;
  height: 70px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.home-slider-button:nth-of-type(1) {
  left: 0;
}

.home-slider-button:nth-of-type(2) {
  left: 70px;
  transform: rotate(180deg);
}

@media only screen and (max-width: 1120px) {
  .home-slider-button {
    width: 48px;
    height: 48px;
  }

  .home-slider-button:nth-of-type(2) {
    left: auto;
    right: 0;
  }

  .home-slider-button > svg {
    width: 18px;
  }
}

.home-slider-button > svg {
  fill: #ffffff;
}

.home-slider-button:hover > svg,
.home-slider-button:focus > svg {
  fill: var(--accent-color);
}

/* OBJECTS */

#home-objects {
  background: #ffffff;
  padding: 48px 16px;
}

@media only screen and (min-width: 1120px) {
  #home-objects {
    padding: 100px calc((100% - (32.2% + 690px)) / 2);
  }
}

#home-objects > div:nth-child(1) {
  font-family: Montserrat;
  font-size: 24px;
}

@media only screen and (max-width: 1120px) {
  #home-objects > div:nth-child(1) {
    font-size: 20px;
  }
}

#home-objects-grid {
  margin-top: 36px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(15, 1fr);
  border-bottom: 1px solid var(--light-grey);
  border-right: 1px solid var(--light-grey);
}

@media only screen and (min-width: 1120px) {
  #home-objects-grid {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(5, 1fr);
  }
}

.home-object-tile {
  border-top: 1px solid var(--light-grey);
  border-left: 1px solid var(--light-grey);
  padding: 24px 56px;
  display: flex;
  align-items: center;
  font-size: 1.05rem;
  cursor: pointer;
  color: var(--secondary-color);
  text-decoration: none;
}

@media only screen and (max-width: 1120px) {
  .home-object-tile {
    border-top: 1px solid var(--light-grey);
    border-left: 1px solid var(--light-grey);
    padding: 8px 16px;
    display: flex;
    align-items: center;
    font-size: 1.05rem;
    cursor: pointer;
    color: var(--secondary-color);
    text-decoration: none;
  }
}

.home-object-tile:hover {
  color: #ffffff;
  background: var(--secondary-color);
}

.home-object-tile > svg {
  fill: var(--primary-color);
  margin-right: 24px;
  border: 1px solid var(--primary-color);
  min-width: 30px;
  height: 30px;
  padding: 5px;
  box-sizing: border-box;
}

/* MAP */

#home-goto-map {
  width: 100%;
  position: relative;
  overflow: hidden;
}

#map-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1120px) {
  #home-goto-map {
    height: 50vh;
  }
}

#home-goto-map-text {
  padding: 8% 0 13% 20%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  white-space: nowrap;
  z-index: 1;
}

#home-goto-map-label {
  font-size: 64px;
  font-weight: bold;
  font-family: Montserrat;
  line-height: 1.5;
}

#home-goto-map-button {
  border: none;
  background: var(--primary-color);
  color: #ffffff;
  padding: 16px 24px;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.3s;
}

#home-goto-map-button:hover {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

#home-goto-map-button::after {
  content: "";
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  margin-left: 8px;
  display: inline-block;
}

@media only screen and (max-width: 1120px) {
  #home-goto-map-label {
    font-size: 24px;
  }

  #home-goto-map-text {
    padding: 5vw 16px 12vw 10vw;
  }

  #home-goto-map-button {
    font-size: 1rem;
  }

  #home-goto-map-button::after {
    width: 5px;
    height: 5px;
    margin-left: 6px;
  }
}

/* NEWS */

#home-news {
  background: var(--primary-color);
  padding: 100px calc((100% - (32.2% + 690px)) / 2);
  width: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: 1120px) and (min-width: 620px) {
  #home-news {
    padding: 48px 40px;
  }
}

@media only screen and (max-width: 620px) {
  #home-news {
    padding: 48px 16px;
  }
}

#home-news-title-row {
  font-family: Montserrat;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  color: #ffffff;
  font-weight: bold;
}

#home-news-title-row > a {
  background: var(--secondary-color);
  border: none;
  padding: 12px 16px;
  position: relative;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.1rem;
  font-family: Lato;
  font-weight: lighter;
  transition: 0.3s;
}

#home-news-title-row > a:hover {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

#home-news-title-row > a::after {
  content: "";
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  margin-left: 8px;
  display: inline-block;
}

@media only screen and (max-width: 1120px) {
  #home-news-title-row {
    font-size: 20px;
  }

  #home-news-title-row > a {
    padding: 8px 12px;
    font-size: 1rem;
  }

  #home-news-title-row > a::after {
    width: 5px;
    height: 5px;
    margin-left: 6px;
  }
}

#home-news-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 40px;
  margin: 36px 0 0 0;
}

@media only screen and (max-width: 1120px) and (min-width: 620px) {
  #home-news-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 620px) {
  #home-news-grid {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

/* WYBUDUJ DOM */

#home-wybuduj-dom {
  background: #ffffff;
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

#home-wd-strug {
  position: absolute;
  right: -200px;
}

#home-wd-rysunek {
  position: absolute;
  right: calc(60vw);
}

#home-wd-text {
  white-space: nowrap;
  z-index: 1;
  position: absolute;
  left: 45vw;
}

@media only screen and (max-width: 730px) {
  #home-wd-strug {
    right: -350px;
    bottom: -50px;
  }

  #home-wd-rysunek {
    opacity: 0;
  }

  #home-wd-text {
    left: 10vw;
  }
}

#home-wd-label {
  font-size: 64px;
  font-weight: bold;
  font-family: Montserrat;
  line-height: 1.5;
}

#home-wd-button {
  border: none;
  background: var(--accent-color);
  color: #ffffff;
  padding: 16px 24px;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 56px;
  text-decoration: none;
  transition: 0.3s;
  display: inline-block;
}

#home-wd-button:hover {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

#home-wd-button::after {
  content: "";
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  margin-left: 8px;
  display: inline-block;
}

@media only screen and (max-width: 1120px) {
  #home-wybuduj-dom {
    height: 50vh;
  }

  #home-wd-label {
    font-size: 24px;
  }

  #home-wd-button {
    font-size: 1rem;
  }

  #home-wd-button::after {
    width: 5px;
    height: 5px;
    margin-left: 6px;
  }
}

/* INSTAGRAM */

#home-instagram {
  background: var(--secondary-color);
  padding: 100px calc((100% - (32.2% + 690px)) / 2);
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

#home-instagram-photos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;
  margin-top: 36px;
}

@media only screen and (max-width: 1120px) and (min-width: 620px) {
  #home-instagram {
    padding: 48px 40px;
  }

  #home-instagram-photos {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 620px) {
  #home-instagram {
    padding: 48px 16px;
  }

  #home-instagram-photos {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

#home-instagram-photos > div {
  width: 100%;
  padding-top: 100%;
  background: var(--primary-color);
}

/* CONTACT */

#home-contact-form {
  background: #ffffff;
  padding: 100px calc((100% - (32.2% + 690px)) / 2);
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

#home-contact-form-label {
  font-size: 24px;
  font-family: Montserrat;
}

@media only screen and (max-width: 1120px) {
  #home-contact-form {
    padding: 48px 16px;
  }

  #home-contact-form-label {
    font-size: 20px;
  }
}

#home-contact-form-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 36px;
}

.home-contact-form-input-wrapper {
  position: relative;
}

.home-contact-form-input-wrapper:nth-child(3) {
  align-self: center;
}

.home-contact-form-input-wrapper > input,
.home-contact-form-input-wrapper > textarea {
  background: transparent;
  font-family: Lato;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 12px;
  border: 1px solid var(--light-grey);
  font-size: 1.1rem;
}

.home-contact-form-input-wrapper > textarea {
  height: 100%;
  resize: vertical;
  min-height: 108px;
}

.home-contact-form-input-wrapper > label {
  position: absolute;
  padding: 0px 4px;
  top: 8px;
  left: 8px;
  font-size: 1.1rem;
  color: var(--light-grey);
  background: #ffffff;
  transition: 0.2s;
}

.home-contact-form-input-wrapper > label {
  top: -8px;
  font-size: 0.8rem;
}

.home-contact-form-input-wrapper:nth-child(4) {
  grid-column: 2 / 4;
  grid-row: 1 / 3;
}

@media only screen and (max-width: 1120px) {
  #home-contact-form-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .home-contact-form-input-wrapper:nth-child(4) {
    grid-column: auto;
    grid-row: auto;
  }
}

#home-contact-form-captcha-wrapper {
  justify-self: center;
  border: 2px solid transparent;
}

#home-contact-form-send {
  border: none;
  background: var(--secondary-color);
  width: 100%;
  height: 47px;
  color: #ffffff;
  font-size: 1.1rem;
  font-family: Montserrat;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  align-self: center;
}

@media only screen and (max-width: 1120px) {
  #home-contact-form-send {
    padding: 16px 0;
  }
}

#home-contact-form-send > svg {
  fill: var(--accent-color);
  margin-left: 100px;
  position: absolute;
  transition: 0.2s;
  animation: 0.3s unsendAnimation forwards;
}

#home-contact-form-send:hover > svg,
#home-contact-form-send:focus > svg {
  animation: 0.3s sendAnimation forwards;
}

@keyframes sendAnimation {
  0% {
    transform: translate(0px, -0px);
  }
  100% {
    transform: translate(30px, -30px);
  }
}

@keyframes unsendAnimation {
  0% {
    transform: translate(-30px, 30px);
  }
  100% {
    transform: translate(0px, -0px);
  }
}

#home-contact-form-email-sent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-size: 40px;
  letter-spacing: 3px;
  z-index: 1;
  overflow: hidden;
  transition: 0.3s;
}

#home-contact-form-email-sent > div {
  position: absolute;
  top: 25%;
  opacity: 0;
  transition: top 3s, opacity 2s 1s;
}

#home-contact-form-email-sent > svg {
  position: absolute;
  width: 200px;
  height: 200px;
  opacity: 0.4;
  fill: var(--accent-color);
  z-index: -1;
  margin-left: 400px;
  margin-top: 100px;
}

@keyframes sentEmail {
  0% {
    margin-left: -600px;
    margin-top: -400px;
    transform: rotate(90deg);
  }
  30% {
    margin-left: 0;
    margin-top: 500px;
    transform: rotate(90deg);
  }
  31% {
    transform: rotate(0deg);
  }
  100% {
    margin-left: 400px;
    margin-top: 100px;
    transform: rotate(0deg);
  }
}
