#mapa-body {
  background: var(--primary-color);
  width: 100%;
  height: calc(100vh - 50px);
  position: absolute;
  top: 50px;
}

#mapa-body a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

#mapa-body a:hover {
  text-decoration: underline;
}

#map-filters-button {
  border: none;
  outline: transparent;
  position: absolute;
  top: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.3s;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  left: 203px;
  width: 150px;
  max-width: calc(100vw - 213px);
}

#map-filters-button:hover,
#map-filters-button:focus {
  background: rgb(235, 235, 235);
}

#map-filters-body {
  width: 400px;
  background: #ffffff;
  border-radius: 5px;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  height: 100%;
  transform: translateX(-400px);
  position: relative;
  max-width: 100vw;
}

#map-filters-body > div {
  overflow: auto;
  height: calc(100%);
  width: 100%;
  padding: 32px 16px 0 16px;
  box-sizing: border-box;
  position: relative;
}

#map-filters-body::after {
  content: "";
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
  height: 125px;
  background: linear-gradient(transparent 0%, #ffffff 25%);
}

.close-map-filters-button {
  position: absolute;
  right: 32px;
  top: 16px;
  background: #ffffff;
  border: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.close-map-filters-button::before,
.close-map-filters-button::after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 100%;
  position: absolute;
  transform: rotate(45deg);
  background: var(--text-color);
}

.close-map-filters-button::before {
  transform: rotate(45deg);
}

.close-map-filters-button::after {
  transform: rotate(-45deg);
}

.map-filters-save-button {
  position: absolute !important;
  bottom: 16px;
  left: -184px;
  z-index: 1 !important;
  width: 368px !important;
  height: 43px;
}

@media only screen and (max-width: 400px) {
  .map-filters-save-button {
    left: auto;
    right: 16px;
    width: calc(100vw - 32px) !important;
  }
}

#map-object-info-modal {
  width: 400px;
  background: #ffffff;
  border-radius: 5px;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  height: 100%;
  transform: translateX(-400px);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
}

.close-map-object-info {
  right: 16px;
  top: 16px;
  background: transparent;
  border-radius: 100%;
  transition: 0.1s;
  outline: transparent;
}

.close-map-object-info:hover,
.close-map-object-info:focus {
  background: #ffffff;
  box-shadow: 0px 0px 5px 5px #ffffff;
}

#map-object-info-photo {
  width: 100%;
  height: 400px;
  min-height: 400px;
  background: grey;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

#map-object-info-photo > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

#map-object-info-modal > h2 {
  font-family: Montserrat;
  padding: 16px;
  margin: 0;
}

#o-rodzaj,
#o-data,
#o-zabytek {
  padding: 0 16px;
}

#map-object-info-description {
  padding: 16px;
  max-height: 100%;
  position: relative;
  overflow: hidden;
}

#map-object-info-description * {
  font-family: Lato !important;
  font-size: 1rem !important;
  background: transparent !important;
}

#map-object-info-description::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3rem;
  bottom: 0;
  left: 0;
  background: linear-gradient(transparent 0%, #fff 85%);
}

#map-object-info-link {
  margin: 16px;
  text-align: end;
}
