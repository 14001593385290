#footer-body {
  --border-color: #e6e6e6;
  --text-color: #666666;
  --accent-color: #daad86;

  color: var(--text-color);
  box-sizing: border-box;
  padding: 0 calc((100% - (32.2% + 690px)) / 2);
  line-height: 23px;
  clear: both;
}

#cookies-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--border-color);
  padding: 10px 0;
}

#cookies-info > button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  background: transparent;
  cursor: pointer;
  position: relative;
  border: none;
  outline: transparent;
}

#cookies-info > button::before,
#cookies-info > button::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 2px;
  background: var(--text-color);
  transform: rotate(45deg);
  z-index: -1;
}

#cookies-info > button:hover::before,
#cookies-info > button:hover::after,
#cookies-info > button:focus::before,
#cookies-info > button:focus::after {
  background: var(--primary-color);
}

#cookies-info > button::before {
  transform: rotate(45deg);
}

#cookies-info > button::after {
  transform: rotate(-45deg);
}

#footer-info {
  border-top: 1px solid var(--border-color);
  padding: 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-column {
  max-width: 15%;
  margin-top: 43px;
}

#footer-column-herb {
  margin-top: 66px;
}

@media only screen and (max-width: 1120px) {
  #cookies-info {
    padding: 10px 12px;
    box-sizing: border-box;
  }

  #footer-info {
    flex-direction: column;
    padding: 32px 16px;
    box-sizing: border-box;
  }

  .footer-column {
    width: 100%;
    max-width: 100%;
  }

  #footer-column-herb {
    margin-top: 0px;
  }
}

.footer-column-title {
  color: var(--accent-color);
  font-family: Montserrat;
}

.footer-column-line {
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.footer-column-line > a {
  text-decoration: none;
  color: inherit;
}

.footer-column-line > a:hover {
  text-decoration: underline;
}

.footer-column-line > svg {
  width: 18px;
  height: 19px;
  margin-right: 12px;
  margin-top: 2px;
  fill: var(--text-color);
}

#footer-social-media {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

#footer-social-media > a > svg {
  fill: var(--text-color);
  width: 24px;
  height: 24px;
  padding: 0 2px;
  cursor: pointer;
}

#footer-social-media > a:nth-child(1):hover > svg {
  fill: var(--facebook-color) !important;
}

#footer-social-media > a:nth-child(2):hover > svg {
  fill: var(--instagram-color) !important;
}

#footer-social-media > a:nth-child(3):hover > svg {
  fill: var(--youtube-color) !important;
}

#footer-divider {
  min-width: 1px;
  background: var(--border-color);
}

#footer-bottom {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
}

@media only screen and (max-width: 1120px) {
  #footer-bottom {
    flex-direction: column;
    height: auto;
    padding: 6px 12px;
    box-sizing: border-box;
  }
}
